<template>
<div>
    <h2 class="ma-2">Välj ett lag och se hur det går!</h2>
    
    <v-container >
        <v-row justify="center">
            <v-col cols="12" md="4">
                <v-autocomplete v-on:change="updateTeamProgress" key="team" v-model="selectedTeam" :items="teams" item-text="t_table_name" item-value="t_id" label="Lag" outlined></v-autocomplete>
            </v-col>
        </v-row>
    </v-container>    
    <v-container v-if="selectedTeam">
        <h3 class="ma-2">Lagstatus</h3>
        <v-row justify="center" class="ma-3">
            <v-col cols="12" md="4">
                <v-card>
                    <v-card-text>
                        <!--Show start number, start time, finish time, total rally time-->
                        <p>Lagnamn: {{ team.t_name }}</p>
                        <p>Startnummer: {{ team.t_start_position }}</p>
                        <p>Starttid: {{ team.t_ts_start ? new Date(team.t_ts_start).toLocaleTimeString(): "" }}</p>
                        <p>Måltid: {{ team.t_ts_finish ? new Date(team.t_ts_finish).toLocaleTimeString() : "" }}</p>
                        <p>Körtid: {{ this.raceTime ? this.raceTime : "00:00:00" }}</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


        <h3 class="ma-2">Stationer</h3>
        <v-row justify="center" class="ma-3">
            <v-col cols="12" md="8">
                <v-data-table
                    dense
                    :headers="stationHeaders"
                    :items="stationResults"
                    sort-by="stationSNumber"
                    item-key="name"
                    class="elevation-1"
                ></v-data-table>            
            </v-col>
        </v-row>
        
        <v-divider class="my-8"/>
        <h3 class="ma-2">HÄFTiga platser</h3>
        <v-row justify="center" v-if="doneHaftigaPlaces.length === 0">
            <v-col cols="12" md="4">
                <h5>Inga HÄFTiga platser har skickats in</h5>
            </v-col>
        </v-row>
        <v-row v-else justify="center">
            <v-col
                cols="12"
                sm="4"
                v-for="n in doneHaftigaPlaces"
                :key="n"
                >
                <v-sheet class="ma-3">
                    <h4>#{{ n }}</h4>
                    <v-img class="rounded-lg" :src="getHaftigImageUrl(n)" />
                </v-sheet>
            </v-col>
        </v-row>

        <v-divider class="my-8"/>
        <h3 class="ma-2">Uppdrag</h3>
        <v-row justify="center" v-if="doneMissions.length === 0">
            <v-col cols="12" md="4">
                <h5>Inga uppdrag har skickats in</h5>
            </v-col>
        </v-row>
        <v-row v-else justify="center">
            <v-col
                cols="12"
                sm="4"
                v-for="n in doneMissions"
                :key="n"
                >
                <v-sheet class="ma-3">
                    <h4>#{{ n }}</h4>
                    <video height="100%" width="100%" v-if="doneMissionsData[n].image_or_video === true" controls :src="doneMissionsData[n].url">
                        <a target="_blank" :href="doneMissionsData[n].url">{{ doneMissionsData[n].url }}</a>
                    </video>
                    <v-img v-else class="rounded-lg" :src="getMissionImageUrl(n)" />
                </v-sheet>
            </v-col>
        </v-row>

        <v-divider class="my-8"/>
        <h3 class="ma-2">Kluringar</h3>
        <v-row justify="center">
            <v-col cols="12" md="8">
                <v-data-table
                    dense
                    :headers="trickyHeaders"
                    :items="trickyResults"
                    sort-by="trickyQuestionQId"
                    item-key="name"
                    class="elevation-1"
                >
                <template v-slot:item.correct="{ item }">
                    <v-chip class="ma-1" :color="item.correct === 'Ja' ? 'green' : 'red'" dark>
                        {{ item.correct }}
                    </v-chip>
                    
                </template>
                </v-data-table>            
            </v-col>
        </v-row>
        <v-divider class="my-8"/>

        <h3 class="ma-2">Bilunderhållning</h3>
        <v-row justify="center" class="ma-3">
            <v-col cols="12" md="8">
                <v-data-table
                    dense
                    :headers="splekHeaders"
                    :items="splekResults"
                    sort-by="splekSplekId"
                    item-key="name"
                    class="elevation-1"
                >
                <template v-slot:item.correct="{ item }">
                    <v-chip class="ma-1" :color="item.correct === 'Ja' ? 'green' : 'red'" dark>
                        {{ item.correct }}
                    </v-chip>
                    
                </template>
                </v-data-table>            
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
import { mapActions } from "vuex"

export default {
    name: 'RallyProgress',
    data: () => ({
        timer: null,
        raceTime: null,
        selectedTeam: null,
        teams: [],
        team: {},
        doneHaftigaPlaces: [],
        doneHaftigaPlacesData: [],
        stationHeaders: [
            {
                text: 'Stationsnummer',
                align: 'start',
                value: 'stationSNumber',
            },
            { text: 'Upplåst tid', value: 'r_ts_unlock' },
            { text: 'Antal hjälprebusar', value: 'r_help' },
        ],
        stationResults: [],
        splekHeaders: [
            {
                text: 'Fråga',
                align: 'start',
                value: 'splekSplekId',
            },
            { text: 'Tid för svar', value: 'ts_insert' },
            { text: 'Svar', value: 'answer' },
            { text: 'Rätt', value: 'correct', color: 'green'}
        ],
        splekResults: [],
        trickyHeaders: [
            {
                text: 'Fråga',
                align: 'start',
                value: 'trickyQuestionQId',
            },
            { text: 'Tid för svar', value: 'ts_insert' },
            { text: 'Svar', value: 'answer' },
            { text: 'Facit', value: 'correct_answer'},
            { text: 'Rätt', value: 'correct', color: 'green'}
        ],
        trickyResults: [],
        doneMissions: [],
        doneMissionsData: null,
    }),
    created () {
        this.initialize()
    },
    methods: {
        ...mapActions(["getAllTeams","getTeamAnsweredTrickyQuestions", "getAllTrickyQuestions", "getTeamDoneHaftigaPlaces", "getTeamDoneMissions", "getTeamStationProgress", "getTeamAnsweredSplekQuestions"]),
        async initialize () {
            const teams = await this.getAllTeams()
            // Add t_table_name and sort by t_start_position
            teams.forEach(team => {
                team.t_table_name = team.t_start_position + ": " + team.t_name
            })
            teams.sort((a, b) => a.t_start_position - b.t_start_position)
            this.teams = teams
        },
        async updateTeamProgress() {
            if (!this.selectedTeam) {
                return
            }

            // select team from this.teams where t_id == this.selectedTeam
            const team = this.teams.find(team => team.t_id === this.selectedTeam)
            this.team = team

            // Race time
            this.updateRaceTime();

            // Stations
            const stations = await this.getTeamStationProgress(this.selectedTeam)

            // convert all .r_ts_unlock to time string
            stations.forEach(station => {
                station.r_ts_unlock = new Date(station.r_ts_unlock).toLocaleTimeString()
            })
            this.stationResults = stations
            
            // HÄFTiga-platser
            const haftig = await this.getTeamDoneHaftigaPlaces(this.selectedTeam)
            this.doneHaftigaPlaces = haftig.doneHaftigaPlaces
            this.doneHaftigaPlacesData = haftig.doneHaftigaPlacesData
            this.doneHaftigaPlaces.sort((a, b) => a - b)

            // Splek
            const splekResults = await this.getTeamAnsweredSplekQuestions(this.selectedTeam)
            // convert all .ts_insert to time string
            splekResults.forEach(result => {
                result.ts_insert = new Date(result.ts_insert).toLocaleTimeString()
            })
            // convert true to "Ja" and false to "Nej"
            splekResults.forEach(result => {
                result.correct = result.correct ? "Ja" : "Nej"
            })
            this.splekResults = splekResults

            // Missions
            const missions = await this.getTeamDoneMissions(this.selectedTeam)
            this.doneMissions = missions.doneMissions
            this.doneMissionsData = missions.doneMissionsData
            this.doneMissions.sort((a, b) => a - b)

            // Tricky
            const trickyResults = await this.getTeamAnsweredTrickyQuestions(this.selectedTeam)
            const trickyQuestions = await this.getAllTrickyQuestions()
            // convert all .ts_insert to time string
            trickyResults.forEach(result => {
                result.ts_insert = new Date(result.ts_insert).toLocaleTimeString()
                
                // Add correct_answer to each result
                const question = trickyQuestions.find(q => q.q_id === result.trickyQuestionQId)
                result.correct_answer = question.correct_answer
                // check if answer is correct by making lowercase and removing whitespace
                result.correct = result.answer.toLowerCase().replace(/\s/g, '') === question.correct_answer.toLowerCase().replace(/\s/g, '') ? "Ja" : "Nej"
            })
            this.trickyResults = trickyResults
        },
        getHaftigImageUrl(haftigNumber) {
            return this.doneHaftigaPlacesData[haftigNumber].url
        },
        getMissionImageUrl(missionNumber) {
            return this.doneMissionsData[missionNumber].url
        },
        updateRaceTime() {
            const startTime = this.team.t_ts_start ? new Date(this.team.t_ts_start) : new Date()
            const endTime = this.team.t_ts_finish ? new Date(this.team.t_ts_finish) : new Date()
            const lunchStartTime = this.team.t_ts_lunch_in ? new Date(this.team.t_ts_lunch_in) : new Date()
            const lunchEndTime = this.team.t_ts_lunch_out ? new Date(this.team.t_ts_lunch_out) : new Date()
            let raceTime = endTime - startTime - (lunchEndTime - lunchStartTime)
            const raceHours = Math.floor(raceTime / 3600000)
            const raceMinutes = Math.floor((raceTime - (raceHours * 3600000)) / 60000)
            const raceSeconds = Math.floor((raceTime - (raceHours * 3600000) - (raceMinutes * 60000)) / 1000)
            
            // Convert to 00:00:00 format
            const raceHoursStr = raceHours < 10 ? "0" + raceHours : raceHours
            const raceMinutesStr = raceMinutes < 10 ? "0" + raceMinutes : raceMinutes
            const raceSecondsStr = raceSeconds < 10 ? "0" + raceSeconds : raceSeconds
            this.raceTime = raceHoursStr + ":" + raceMinutesStr + ":" + raceSecondsStr
        },
    },
    mounted () {
        // Update team progress every 10s
        this.timer = setInterval(() => {
            this.updateTeamProgress();
        }, 10000)
    },
    beforeDestroy() {
        clearInterval(this.timer)
    }
}
</script>